import React, { useState } from 'react';

import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';
import WelcomeBanner from '../partials/dashboard/WelcomeBanner';
import ModalBasic from '../components/ModalBasic';
import FontCard from '../partials/dashboard/FontCard';
import { getFirestore, collection, getDocs, addDoc } from 'firebase/firestore';
import firebaseApp from '../firebase_utils/FirebaseConfig';
import { useHistory } from 'react-router-dom';
function Dashboard() {
  const history = useHistory()
  const db = getFirestore(firebaseApp);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [newBookmarkModalOpen, setNewBookmarkModalOpen] = useState(false);
  const [box, setBox] = useState([]);
  if (!localStorage.getItem('user')){
    history.push('/signin')
  }
  const addToBox = async (e) => {
    try {
      e.preventDefault();
      let formData = Object.fromEntries(new FormData(e.target));
      const docRef = await addDoc(collection(db, "uibox"), {
        title: formData.title,
        url: formData.url,
        collection: formData.collection?formData.collection:'General',
        stars: formData.stars?Number(formData.stars):0,
        comments: formData.comments,
        userId:'niyi'
      });
      if (docRef.id){
        fetchBox();
      }
      setNewBookmarkModalOpen(false);
    } catch (err) {
      console.log('err',err)

    }
  }
  const fetchBox = async () => {
    try {
      let items = [];
      const querySnapshot = await getDocs(collection(db, "uibox"));
      querySnapshot.forEach((doc) => {
        items.push(doc.data())
      });
      setBox(items);
    } catch (error) {
      console.log("error", error);
    }
  };
  React.useEffect(() => {
    fetchBox();
},[]);
  

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

            {/* Welcome banner */}
            <WelcomeBanner />

            {/* Dashboard actions */}
            <div className="mb-8">
              <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                <button className="btn bg-indigo-500 hover:bg-indigo-600 text-white" onClick={(e) => { e.stopPropagation(); setNewBookmarkModalOpen(true); }}>
                    <svg className="w-4 h-4 fill-current opacity-50 flex-shrink-0" viewBox="0 0 16 16">
                        <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                    </svg>
                    <span className="hidden xs:block ml-2">New Bookmark</span>
                </button>
                <ModalBasic id="feedback-modal" modalOpen={newBookmarkModalOpen} setModalOpen={setNewBookmarkModalOpen} title="New Bookmark">
                  <form onSubmit={addToBox}>
                    <div className="px-5 py-4">
                      <div className="text-sm">
                        <div className="font-medium text-gray-800 mb-3">Add a new item to your box ✨</div>
                      </div>
                      <div className="space-y-3">
                        <div>
                          <label className="block text-sm font-medium mb-1" htmlFor="name">Item Name <span className="text-red-500">*</span></label>
                          <input id="title" name="title" className="form-input w-full px-2 py-1" type="text" required />
                        </div>
                        <div>
                          <label className="block text-sm font-medium mb-1" htmlFor="email">Url <span className="text-red-500">*</span></label>
                          <input id="url" name="url" className="form-input w-full px-2 py-1" type="text" required />
                        </div>
                        <div>
                          <label className="block text-sm font-medium mb-1" htmlFor="email">Collection<span className="text-red-500"></span></label>
                          <input id="collection" name="collection" className="form-input w-full px-2 py-1" type="text" />
                        </div>
                        <div>
                          <label className="block text-sm font-medium mb-1" htmlFor="feedback">Notes</label>
                          <textarea id="comments" name="comments" className="form-textarea w-full px-2 py-1" rows="4"></textarea>
                        </div>
                        <div>
                          <label className="block text-sm font-medium mb-1" htmlFor="email">Rate this item<span className="text-red-500"></span></label>
                          <input id="stars" name="stars" className="form-input w-full px-2 py-1" type="number" max={5} min={0}/>
                        </div>
                      </div>
                    </div>
                    {/* Modal footer */}
                    <div className="px-5 py-4 border-t border-gray-200">
                      <div className="flex flex-wrap justify-end space-x-2">
                        <button className="btn-sm border-gray-200 hover:border-gray-300 text-gray-600" onClick={(e) => { e.stopPropagation(); setNewBookmarkModalOpen(false); }}>Cancel</button>
                        <button className="btn-sm bg-indigo-500 hover:bg-indigo-600 text-white" type="submit">Save Bookmark</button>
                      </div>
                    </div>
                  </form>
                </ModalBasic> 
              </div>
            </div>

            {/* Cards */}
            <div className="grid grid-cols-12 gap-6">
              {box && box.map((item,i)=>
                <FontCard item={item} key={i}/>
              )}
            </div>

          </div>
        </main>

      </div>

    </div>
  );
}

export default Dashboard;