import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyDbBnHbxcvZE8Rrn9YccWbwgQ5wK4Wk6WE",
    authDomain: "uibox-e7c09.firebaseapp.com",
    projectId: "uibox-e7c09",
    storageBucket: "uibox-e7c09.appspot.com",
    messagingSenderId: "971193125456",
    appId: "1:971193125456:web:14fdf766d892b9cb639f55"
  };
const firebaseApp = initializeApp(firebaseConfig);
export default firebaseApp;