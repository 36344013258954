import React, { useState } from 'react';

function AccountPanel() {

  const [sync, setSync] = useState(false);

  return (
    <div className="flex-grow">
      {/* Panel body */}
      <div className="p-6 space-y-6">
        <h2 className="text-2xl text-gray-800 font-bold mb-5">My Account</h2>
        {/* Profile */}
        <section>
          <h2 className="text-xl leading-snug text-gray-800 font-bold mb-1">Name</h2>
          <div className="mr-2">
            <label className="sr-only" htmlFor="email">Business email</label>
            <input id="name" value="Niyi" disabled className="form-input" type="email" />
          </div>
        </section>
        {/* Email */}
        <section>
          <h2 className="text-xl leading-snug text-gray-800 font-bold mb-1">Email</h2>
          <div className="mr-2">
            <label className="sr-only" htmlFor="email">Email</label>
            <input id="email" value="akinara@live.com" disabled className="form-input" type="email" />
          </div>
        </section>
        {/* Password */}
        <section>
          <h2 className="text-xl leading-snug text-gray-800 font-bold mb-1">Password</h2>
          <div className="mr-2">
            <label className="sr-only" htmlFor="email">Password</label>
            <input id="password" value="BillingBiller" disabled className="form-input" type="password" />
          </div>
        </section>
      </div>
      {/* Panel footer */}
      <footer>
        <div className="flex flex-col px-6 py-5 border-t border-gray-200">
          <div className="flex self-end">
            <button className="btn border-gray-200 hover:border-gray-300 text-gray-600">Cancel</button>
            <button className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3">Save Changes</button>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default AccountPanel;