import React from 'react';
import { Link } from 'react-router-dom';
import Icon from '../../images/icon-01.svg';
import EditMenu from '../../components/DropdownEditMenu';

function FontCard(props) {

  return (
    <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-white shadow-lg rounded-sm border border-gray-200">
      <div className="px-5 pt-5">
        <header className="flex justify-between items-start mb-2">
          <img src={Icon} width="32" height="32" alt="Icon 01" />
          <EditMenu align="right" className="relative inline-flex">
            <li>
              <Link className="font-medium text-sm text-red-500 hover:text-red-600 flex py-1 px-3" to="#0">Remove</Link>
            </li>
          </EditMenu>
        </header>
        <h2 className="text-lg font-semibold text-gray-800 mb-2">{
          props.item?props.item.url?
          <a href={props.item.url.includes('http')?props.item.url:`https://${props.item.url}`} target="_blank">{props.item.title?props.item.title:props.item.url}</a>:props.item.title:'No item'}
        </h2>
        <div className="text-xs font-semibold text-gray-400 uppercase mb-1">{props.item&&props.item.collection?props.item.collection:'General'}</div>
        <div className="flex items-start mb-3">
          <div className="text-sm font-semibold">{props.item&&props.item.stars?Array(props.item.stars>5?5:props.item.stars).fill(0).map((v,i)=><i key={i}>&#9733;</i>):''}</div>
        </div>
      </div>
    </div>
  );
}

export default FontCard;
